export default {
  "page.quote.top.title.1": "A Trusted Choice",
  "page.quote.top.content.1":
    "Our platform brings together numerous professional transportation companies. Every order undergoes strict review and tracking to ensure the safe arrival of your goods.",
  "page.quote.top.title.2": "Simple and Efficient",
  "page.quote.top.content.2":
    "With our app, you can place an order in just a few easy steps. Simply input your cargo details and click confirm—it's that simple!",
  "page.quote.top.title.3": "Fair Pricing",
  "page.quote.top.content.3":
    "At ExtraSpace, you don't need to worry about transporting medium or large goods. We offer competitive pricing to ensure your transportation needs are cost-effective.",
  "page.quote.top.title.4": "Ideal for Large Goods",
  "page.quote.top.content.4":
    "Whether it's furniture, equipment, or any other medium or large cargo, ExtraSpace has got you covered. Our platform is dedicated to connecting every shipment with the right transportation company.",
  "page.quote.login.form.label.phone": "Phone number",
  "page.quote.login.form.label.code": "SMS code",
  "page.quote.login.form.placeholder.phone": "Please input phone number",
  "page.quote.login.form.placeholder.code": "Please input SMS code",
  "page.quote.login.form.error.info.phone": "Please input your phone number.",
  "page.quote.login.form.error.info.code": "Please input the SMS code you got.",
  "page.quote.login.form.button.code": "Send code",
  "page.quote.login.form.button.login": "Login",
  "page.quote.tab.offer": "Offer online",
  "page.quote.tab.published": "Published",
  "page.quote.tab.in.transit": "In transit",
  "page.quote.tab.done": "Done",
  "page.quote.top.alert":
    "The driver will take measurements on-site. Any losses caused by inaccurate information will be your responsibility.",
  "page.quote.form.session.address": "Address info",
  "page.quote.form.session.address.label.pickup": "Pickup",
  "page.quote.form.session.address.placeholder.pickup":
    "Please select the pickup city",
  "page.quote.form.session.address.alert.pickup":
    "Please select the pickup city",
  "page.quote.form.session.address.label.pickup.ls": "Liftgate service",
  "page.quote.form.session.address.label.pickup.type": "Address type",
  "page.quote.form.session.address.alert.pickup.type":
    "Please select the pickup address type",
  "page.quote.form.session.address.type.residential": "Residential",
  "page.quote.form.session.address.type.commercial": "Commercial",
  "page.quote.form.session.address.label.delivery": "Delivery",
  "page.quote.form.session.address.placeholder.delivery":
    "Please select the delivery city",
  "page.quote.form.session.address.alert.delivery":
    "Please select the delivery city",
  "page.quote.form.session.address.label.delivery.ls": "Liftgate service",
  "page.quote.form.session.address.label.delivery.type": "Address type",
  "page.quote.form.session.address.alert.delivery.type":
    "Please select the delivery address type",
  "page.quote.form.session.cargo": "Cargo info",
  "page.quote.form.session.cargo.label.pickup.type": "Handling unit",
  "page.quote.form.session.cargo.label.quantity": "Count of pallets",
  "page.quote.form.session.cargo.alert.quantity":
    "Please input count of pallets",
  "page.quote.form.session.cargo.label.sub.pallet.size":
    "Single pallet size: 40in × 48in",
  "page.quote.form.session.cargo.label.length": "Length",
  "page.quote.form.session.cargo.alert.length": "Please input length",
  "page.quote.form.session.cargo.label.width": "Width",
  "page.quote.form.session.cargo.alert.width": "Please input width",
  "page.quote.form.session.cargo.label.height": "Height",
  "page.quote.form.session.cargo.alert.height": "Please input height",
  "page.quote.form.session.cargo.label.weight": "Weight lbs",
  "page.quote.form.session.cargo.alert.weight": "Please input weight",
  "page.quote.form.session.cargo.label.date": "Pickup time",
  "page.quote.form.session.cargo.alert.date": "Please select date range",
  "page.quote.form.submit.button": "Get a quote",
  "page.quote.price.description":
    "Here is the estimated cost for your shipment : ",
  "page.quote.price.pickup": "Pickup",
  "page.quote.price.delivery": "Delivery",
  "page.quote.price.future": "Future price",
  "page.quote.price.next.step": "Next step",

  "page.quote.place.order.step.address.info": "Address Info",
  "page.quote.place.order.step.cargo.info": "Cargo Info",
  "page.quote.place.order.step.order.info": "Order Info",

  "page.quote.place.order.top.alert":
    "Please fill in the information accurately. Losses caused by inaccurate information will need to be borne by yourself.",
  "page.quote.place.order.form.1.section.1": "Sender Information",
  "page.quote.place.order.form.1.section.1.label.name":
    "Company or Sender's Name",
  "page.quote.place.order.form.1.section.1.placeholder.name":
    "Please enter the full name",
  "page.quote.place.order.form.1.section.1.empty.name":
    "Please enter the pickup full name",
  "page.quote.place.order.form.1.section.1.label.address":
    "Detailed Sender Address",
  "page.quote.place.order.form.1.section.1.label.line.1": "Address Line 1",
  "page.quote.place.order.form.1.section.1.placeholder.line.1":
    "Please enter the address information",
  "page.quote.place.order.form.1.section.1.empty.line.1":
    "Please enter the pickup address information",
  "page.quote.place.order.form.1.section.1.label.line.2": "Address Line 2",
  "page.quote.place.order.form.1.section.1.placeholder.line.2":
    "Please enter the address information",
  "page.quote.place.order.form.1.section.1.label.contact":
    "Sender's Contact Information",
  "page.quote.place.order.form.1.section.1.label.phone": "Phone",
  "page.quote.place.order.form.1.section.1.placeholder.phone":
    "Please enter the phone number",
  "page.quote.place.order.form.1.section.1.empty.phone":
    "Please enter the pickup phone number",
  "page.quote.place.order.form.1.section.1.label.email": "Email",
  "page.quote.place.order.form.1.section.1.placeholder.email":
    "Please enter the email address",
  "page.quote.place.order.form.1.section.1.label.save": "Save to Address Book",

  "page.quote.place.order.form.1.section.2": "Recipient Information",
  "page.quote.place.order.form.1.section.2.label.name":
    "Company or Recipient's Name",
  "page.quote.place.order.form.1.section.2.placeholder.name":
    "Please enter the full name",
  "page.quote.place.order.form.1.section.2.empty.name":
    "Please enter the delivery full name",
  "page.quote.place.order.form.1.section.2.label.address":
    "Detailed Recipient Address",
  "page.quote.place.order.form.1.section.2.label.line.1": "Address Line 1",
  "page.quote.place.order.form.1.section.2.placeholder.line.1":
    "Please enter the address information",
  "page.quote.place.order.form.1.section.2.empty.line.1":
    "Please enter the delivery address information",
  "page.quote.place.order.form.1.section.2.label.line.2": "Address Line 2",
  "page.quote.place.order.form.1.section.2.placeholder.line.2":
    "Please enter the address information",
  "page.quote.place.order.form.1.section.2.label.contact":
    "Recipient's Contact Information",
  "page.quote.place.order.form.1.section.2.label.phone": "Phone",
  "page.quote.place.order.form.1.section.2.placeholder.phone":
    "Please enter the phone number",
  "page.quote.place.order.form.1.section.2.empty.phone":
    "Please enter the delivery phone number",
  "page.quote.place.order.form.1.section.2.label.email": "Email",
  "page.quote.place.order.form.1.section.2.placeholder.email":
    "Please enter the email address",
  "page.quote.place.order.form.1.error.email":
    "Please enter a valid email address",
  "page.quote.place.order.form.1.section.2.label.save": "Add to Address Book",
  "page.quote.place.order.form.1.section.2.label.sign": "Recipient's Signature",

  "page.quote.place.order.form.1.back": "Requote",
  "page.quote.place.order.form.1.next": "Next",

  "page.quote.place.order.form.2": "Cargo Information",
  "page.quote.place.order.form.2.label.type": "Cargo Type",
  "page.quote.place.order.form.2.placeholder.type":
    "e.g., 'Furniture' or 'Bicycle'",
  "page.quote.place.order.form.2.empty.type": "Please enter the cargo type",
  "page.quote.place.order.form.2.label.method": "Delivery Method",
  "page.quote.place.order.form.2.label.quantity": "Number of Pallets",
  "page.quote.place.order.form.2.label.length": "Length",
  "page.quote.place.order.form.2.label.width": "Width",
  "page.quote.place.order.form.2.label.height": "Height",
  "page.quote.place.order.form.2.label.weight": "Total Cargo Weight (lbs)",
  "page.quote.place.order.form.2.label.note": "Remarks",
  "page.quote.place.order.form.2.placeholder.note": "Please enter remarks",
  "page.quote.place.order.form.2.label.safe": "No Hazardous Materials",
  "page.quote.place.order.form.2.empty.safe":
    "Please confirm whether there are no hazardous materials",

  "page.quote.place.order.form.2.back": "Back",
  "page.quote.place.order.form.2.next": "Confirm",

  "page.quote.place.order.form.3.label.price": "Order Price",
  "page.quote.place.order.form.3.label.date": "Pickup Time",
  "page.quote.place.order.form.3.label.info.pickup": "Pickup Information",
  "page.quote.place.order.form.3.label.type": "Address Type:",
  "page.quote.place.order.form.3.label.ls": "Liftgate service required",
  "page.quote.place.order.form.3.label.info.delivery": "Delivery Information",
  "page.quote.place.order.form.3.label.type.cargo": "Cargo Type",
  "page.quote.place.order.form.3.label.info.cargo": "Cargo Information",
  "page.quote.place.order.form.3.label.weight": "Weight",
  "page.quote.place.order.form.3.label.note": "Remarks",

  "page.quote.place.order.form.3.back": "Back",
  "page.quote.place.order.form.3.pay": "Pay",

  "page.quote.place.order.submit.address.error.content.pickup":
    "The pickup address you entered is incorrect. Do you want to proceed anyway?",
  "page.quote.place.order.submit.address.error.content.delivery":
    "The delivery address you entered is incorrect. Do you want to proceed anyway?",
  "page.quote.place.order.submit.address.error.confirm": "Proceed Anyway",
  "page.quote.place.order.submit.address.error.cancel": "Cancel",

  "page.quote.order.list.loading": "Loading...",
  "page.quote.order.list.empty.title": "No Orders Yet",
  "page.quote.order.list.empty.title.sub": "Place an order now !",
  "page.quote.order.list.empty.goto.quote": "Place Order",
  "page.quote.order.list.item.label.order.id": "Order ID",
  "page.quote.order.list.item.label.price": "Price",
  "page.quote.order.list.item.label.pickup": "Pickup",
  "page.quote.order.list.item.label.delivery": "Delivery",
  "page.quote.order.list.item.label.time.pickup": "Pickup Time",
  "page.quote.order.list.item.label.time.delivery": "Delivery Time",
  "page.quote.order.list.item.label.time.receive": "Arrival Time",
  "page.quote.order.list.item.label.type.cargo": "Cargo Type",
  "page.quote.order.list.item.label.size": "Cargo Dimensions",
  "page.quote.order.list.item.label.size.length": "L",
  "page.quote.order.list.item.label.size.width": "W",
  "page.quote.order.list.item.label.size.height": "H",
  "page.quote.order.list.item.label.pay": "Pay",
  "page.quote.order.list.item.label.delete": "Delete",

  "page.quote.order.detail.item.label.order.id": "Order ID",
  "page.quote.order.detail.item.label.price": "Order Price",
  "page.quote.order.detail.item.label.info.pickup": "Pickup Information",
  "page.quote.order.detail.item.label.info.delivery": "Delivery Information",
  "page.quote.order.detail.item.label.type.address": "Address Type",
  "page.quote.order.detail.item.label.type.address.residential": "Residential",
  "page.quote.order.detail.item.label.type.address.commercial": "Commercial",
  "page.quote.order.detail.item.label.time.pickup": "Pickup Time",
  "page.quote.order.detail.item.label.time.delivery": "Delivery Time",
  "page.quote.order.detail.item.label.status.receive": "Cargo Delivered",
  "page.quote.order.detail.item.alert.message.receive":
    "Note: The driver has delivered the cargo. Please confirm the receipt. If not received, file a complaint on the platform.",
  "page.quote.order.detail.item.label.type.cargo": "Cargo Type",
  "page.quote.order.detail.item.label.size": "Cargo Dimensions",
  "page.quote.order.detail.item.label.size.length": "L",
  "page.quote.order.detail.item.label.size.width": "W",
  "page.quote.order.detail.item.label.size.height": "H",
  "page.quote.order.detail.item.label.size.weight": "W",
  "page.quote.order.detail.item.label.note": "Remarks",
  "page.quote.order.detail.item.content.bol": "Click here to view the BOL form",
  "page.quote.order.detail.item.label.info.payment": "Payment Information",
  "page.quote.order.detail.item.label.pay": "Pay",

  "page.quote.card.form.label.bill.address": "Billing Address",
  "page.quote.card.form.required.bill.address":
    "Please select a billing address",
  "page.quote.card.form.label.bill.address.same": "Same as Pickup Address",
  "page.quote.card.form.label.bill.address.new": "Enter a New Billing Address",
  "page.quote.card.form.label.bill.address.new.name": "Name",
  "page.quote.card.form.required.bill.address.new.name": "Please enter name",
  "page.quote.card.form.placeholder.bill.address.new.name": "Please enter name",
  "page.quote.card.form.label.bill.address.new.address.line.1":
    "Address Line 1",
  "page.quote.card.form.required.bill.address.new.address.line.1":
    "Please enter the address information",
  "page.quote.card.form.placeholder.bill.address.new.address.line.1":
    "Please enter the address information",
  "page.quote.card.form.label.bill.address.new.address.line.2":
    "Address Line 2",
  "page.quote.card.form.label.bill.address.new.zipcode": "Zip Code",
  "page.quote.card.form.required.bill.address.new.zipcode":
    "Please enter the Zip Code",
  "page.quote.card.form.placeholder.bill.address.new.zipcode":
    "Please enter the Zip Code",
  "page.quote.card.form.label.bill.address.new.city": "City",
  "page.quote.card.form.required.bill.address.new.city":
    "Please enter the City",
  "page.quote.card.form.placeholder.bill.address.new.city":
    "Please enter the City",
  "page.quote.card.form.label.bill.address.new.state": "State",
  "page.quote.card.form.required.bill.address.new.state":
    "Please enter the State",
  "page.quote.card.form.placeholder.bill.address.new.state":
    "Please enter the State",
  "page.quote.card.form.payment.message.top.alert":
    "The platform will not charge until the driver confirms the order.",
  "page.quote.card.form.payment.label.info": "Payment Information",
  "page.quote.card.form.payment.label.card.number": "Bank Card",
  "page.quote.card.form.payment.required.card": "Please select a bank card",
  "page.quote.card.form.payment.label.card.add": "Add a New Bank Card",
  "page.quote.card.form.payment.label.card.add.type": "Card Type",
  "page.quote.card.form.payment.required.card.add.type":
    "Please select a card type",
  "page.quote.card.form.payment.placeholder.card.add.type":
    "Please select a card type",
  "page.quote.card.form.payment.label.card.add.number": "Card Number",
  "page.quote.card.form.payment.required.card.add.number":
    "Please enter the card number",
  "page.quote.card.form.payment.error.card.add.number":
    "Invalid card number format",
  "page.quote.card.form.payment.label.card.add.month": "Expiration Date",
  "page.quote.card.form.payment.required.card.add.month":
    "Please select the expiration month",
  "page.quote.card.form.payment.placeholder.card.add.month":
    "Please select the expiration month",
  "page.quote.card.form.payment.required.card.add.year":
    "Please select the expiration year",
  "page.quote.card.form.payment.placeholder.card.add.year":
    "Please select the expiration year",
  "page.quote.card.form.payment.error.card.add.date": "Invalid expiration date",
  "page.quote.card.form.payment.label.card.add.cvv": "CVC/CVV Code",
  "page.quote.card.form.payment.required.card.add.cvv":
    "Please enter the CVC/CVV",
  "page.quote.card.form.payment.placeholder.card.add.cvv":
    "Please enter the CVC/CVV",
  "page.quote.card.form.payment.error.card.add.cvv":
    "The CVC/CVV must be 3 digits",
  "page.quote.card.form.payment.label.card.add.save": "Save Card",
  "page.quote.card.form.payment.error.card.add":
    "Please complete all card information",
  "page.quote.card.form.payment.label.back": "Previous",
  "page.quote.card.form.payment.label.confirm": "Confirm",
};
