import Home from '@/i18n/zh-CN/home';
import PostRoute from "@/i18n/zh-CN/postRoute";
import Login from '@/i18n/zh-CN/login';
import Order from "@/i18n/zh-CN/order";
import Quote from "@/i18n/zh-CN/quote";

export default {
    "nav.link.home": "首页",
    "nav.link.quote": "获取估价",
    "nav.link.privacy": "隐私政策",
    "nav.link.safety-of-transaction": "交易安全",
    "nav.link.transportation-strategy": "运输政策",
    "nav.link.terms-of-service": "服务条款",
    "nav.link.contact-us": "联系我们",
    "nav.link.manual": "使用手册",
    "nav.link.manual.shipper.app": "寄货人App端",
    "nav.link.manual.carrier.app": "运货人App端",
    "nav.link.manual.carrier.web": "运货人网页端",
    "nav.link.user.prefix": "司机",
    "nav.link.user.prefix.shipper": "用户",
    "nav.link.login": "登录",
    "nav.link.login.carrier": "我是司机",
    "nav.link.login.shipper": "我要寄货",
    "nav.link.order": "订单管理",
    "nav.link.logout": "登出",
    "btn.contact-us": "联系我们",
    "btn.for-shipper": "对于用户",
    "btn.for-carrier": "对于司机/货运机构",
    "btn.download": "立刻下载",
    "btn.app.download": "APP下载",
    "nav.shipper": "用户端",
    "nav.carrier": "司机端",
    "follow-us": "关注我们",
    "message.network.error": "网络异常",
    "message.network.error.token": "身份验证异常",
    "message.network.success.login": "登录成功",
    "message.network.success.upload": "上传成功",
    ...Home,
    ...PostRoute,
    ...Login,
    ...Order,
    ...Quote,
}