import Home from "@/i18n/en-US/home";
import PostRoute from "@/i18n/en-US/postRoute";
import Login from "@/i18n/en-US/login";
import Order from "@/i18n/en-US/order";
import Quote from "@/i18n/en-US/quote";

export default {
  "nav.link.home": "Home",
  "nav.link.quote": "Quote",
  "nav.link.privacy": "Privacy Policy",
  "nav.link.safety-of-transaction": "Transaction and security",
  "nav.link.transportation-strategy": "Shipping policies",
  "nav.link.terms-of-service": "Terms of Service",
  "nav.link.contact-us": "Contact us",
  "nav.link.manual": "Manual",
  "nav.link.manual.shipper.app": "Shipper App version",
  "nav.link.manual.carrier.app": "Carrier App version",
  "nav.link.manual.carrier.web": "Carrier Web version",
  "nav.link.user.prefix": "Driver",
  "nav.link.user.prefix.shipper": "User",
  "nav.link.login": "Login",
  "nav.link.login.carrier": "Carrier",
  "nav.link.login.shipper": "Shipper",
  "nav.link.order": "Order management",
  "nav.link.logout": "Logout",
  "btn.contact-us": "Contact us",
  "btn.for-shipper": "For Shipper",
  "btn.for-carrier": "For Carrier",
  "btn.download": "Download now",
  "btn.app.download": "Download APP",
  "nav.shipper": "Shipper",
  "nav.carrier": "Carrier",
  "follow-us": "Follow Us",
  "message.network.error": "Network error",
  "message.network.error.token": "Identity verification exception",
  "message.network.success.login": "Login success",
  "message.network.success.upload": "Upload success",
  ...Home,
  ...PostRoute,
  ...Login,
  ...Order,
  ...Quote,
};
