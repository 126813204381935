export default {
  "page.quote.top.title.1": "信赖之选",
  "page.quote.top.content.1":
    "我们的平台汇集了众多专业的运输公司，所有的订单都经过严格的审核和跟踪，以确保您的货物安全抵达。",
  "page.quote.top.title.2": "简洁高效",
  "page.quote.top.content.2":
    "通过我们的app，您只需几个简单的步骤就能快速地下单。只要输入货物信息，再点击确认，就完成了！",
  "page.quote.top.title.3": "价格公道",
  "page.quote.top.content.3":
    "在ExtraSpace，您不需要为运输中大型货物而烦恼。我们提供了竞争力的价格，让您的运输需求不再负担沉重。",
  "page.quote.top.title.4": "适合中大型货物",
  "page.quote.top.content.4":
    "无论是家具，设备，还是其他任何中大型货物，ExtraSpace都能轻松应对。我们的平台致力于让每一个货物都找到合适的运输公司。",
  "page.quote.login.form.label.phone": "电话号码",
  "page.quote.login.form.label.code": "验证码",
  "page.quote.login.form.placeholder.phone": "请输入电话号码",
  "page.quote.login.form.placeholder.code": "请输入验证码",
  "page.quote.login.form.error.info.phone": "请输入正确的电话号码",
  "page.quote.login.form.error.info.code": "请输入正确的验证码",
  "page.quote.login.form.button.code": "发送验证码",
  "page.quote.login.form.button.login": "登录",
  "page.quote.tab.offer": "在线下单",
  "page.quote.tab.published": "已发布订单",
  "page.quote.tab.in.transit": "运输中订单",
  "page.quote.tab.done": "已完成订单",
  "page.quote.top.alert":
    "司机将在现场进行测量，如因填写不准确而导致损失，需自行承担责任。",
  "page.quote.form.session.address": "地址信息",
  "page.quote.form.session.address.label.pickup": "取货点",
  "page.quote.form.session.address.placeholder.pickup": "选择取货点",
  "page.quote.form.session.address.alert.pickup": "请选择取货点",
  "page.quote.form.session.address.label.pickup.ls": "升降尾板服务",
  "page.quote.form.session.address.label.pickup.type": "地址类别",
  "page.quote.form.session.address.alert.pickup.type": "请选择取货点地址类别",
  "page.quote.form.session.address.type.residential": "住宅区",
  "page.quote.form.session.address.type.commercial": "商业区",
  "page.quote.form.session.address.label.delivery": "送货点",
  "page.quote.form.session.address.placeholder.delivery": "选择送货点",
  "page.quote.form.session.address.alert.delivery": "请选择送货点",
  "page.quote.form.session.address.label.delivery.ls": "升降尾板服务",
  "page.quote.form.session.address.label.delivery.type": "地址类别",
  "page.quote.form.session.address.alert.delivery.type": "请选择送货点地址类别",
  "page.quote.form.session.cargo": "货物信息",
  "page.quote.form.session.cargo.label.pickup.type": "交货形式",
  "page.quote.form.session.cargo.label.quantity": "货板数量",
  "page.quote.form.session.cargo.alert.quantity": "请填写货板数量",
  "page.quote.form.session.cargo.label.sub.pallet.size":
    "单个pallet尺寸 40in × 48in",
  "page.quote.form.session.cargo.label.length": "长",
  "page.quote.form.session.cargo.alert.length": "请填写货物长度",
  "page.quote.form.session.cargo.label.width": "宽",
  "page.quote.form.session.cargo.alert.width": "请填写货物宽度",
  "page.quote.form.session.cargo.label.height": "高",
  "page.quote.form.session.cargo.alert.height": "请填写货物高度",
  "page.quote.form.session.cargo.label.weight": "货物总重量 lbs",
  "page.quote.form.session.cargo.alert.weight": "请填写货物总重量",
  "page.quote.form.session.cargo.label.date": "取货时间",
  "page.quote.form.session.cargo.alert.date": "请选择取货时间",
  "page.quote.form.submit.button": "估价",
  "page.quote.price.description": "以下为您此次货运的估价 : ",
  "page.quote.price.pickup": "取货点",
  "page.quote.price.delivery": "送货点",
  "page.quote.price.future": "预计",
  "page.quote.price.next.step": "下一步",

  "page.quote.place.order.step.address.info": "地址信息",
  "page.quote.place.order.step.cargo.info": "货物信息",
  "page.quote.place.order.step.order.info": "订单详情",

  "page.quote.place.order.top.alert":
    "请准确填写，因信息不准确造成损失需自行承担。",
  "page.quote.place.order.form.1.section.1": "发货人信息",
  "page.quote.place.order.form.1.section.1.label.name": "公司或发货人姓名",
  "page.quote.place.order.form.1.section.1.placeholder.name": "请输入全名",
  "page.quote.place.order.form.1.section.1.empty.name": "发货人姓名不能为空",
  "page.quote.place.order.form.1.section.1.label.address": "详细发货地址",
  "page.quote.place.order.form.1.section.1.label.line.1": "地址行1",
  "page.quote.place.order.form.1.section.1.placeholder.line.1":
    "请输入地址信息",
  "page.quote.place.order.form.1.section.1.empty.line.1": "发货地址不能为空",
  "page.quote.place.order.form.1.section.1.label.line.2": "地址行2",
  "page.quote.place.order.form.1.section.1.placeholder.line.2":
    "请输入地址信息",
  "page.quote.place.order.form.1.section.1.label.contact": "发货人联系方式",
  "page.quote.place.order.form.1.section.1.label.phone": "电话",
  "page.quote.place.order.form.1.section.1.placeholder.phone": "请输入电话号码",
  "page.quote.place.order.form.1.section.1.empty.phone": "发货人电话不能为空",
  "page.quote.place.order.form.1.section.1.label.email": "邮箱",
  "page.quote.place.order.form.1.section.1.placeholder.email": "请输入邮箱地址",
  "page.quote.place.order.form.1.section.1.label.save": "加入地址簿",

  "page.quote.place.order.form.1.section.2": "收货人信息",
  "page.quote.place.order.form.1.section.2.label.name": "公司或收货人姓名",
  "page.quote.place.order.form.1.section.2.placeholder.name": "请输入全名",
  "page.quote.place.order.form.1.section.2.empty.name": "收货人姓名不能为空",
  "page.quote.place.order.form.1.section.2.label.address": "详细收货地址",
  "page.quote.place.order.form.1.section.2.label.line.1": "地址行1",
  "page.quote.place.order.form.1.section.2.placeholder.line.1":
    "请输入地址信息",
  "page.quote.place.order.form.1.section.2.empty.line.1": "收货地址不能为空",
  "page.quote.place.order.form.1.section.2.label.line.2": "地址行2",
  "page.quote.place.order.form.1.section.2.placeholder.line.2":
    "请输入地址信息",
  "page.quote.place.order.form.1.section.2.label.contact": "收货人联系方式",
  "page.quote.place.order.form.1.section.2.label.phone": "电话",
  "page.quote.place.order.form.1.section.2.placeholder.phone": "请输入电话号码",
  "page.quote.place.order.form.1.section.2.empty.phone": "收货人电话不能为空",
  "page.quote.place.order.form.1.section.2.label.email": "邮箱",
  "page.quote.place.order.form.1.section.2.placeholder.email": "请输入邮箱地址",
  "page.quote.place.order.form.1.error.email": "请输入正确的邮箱地址",
  "page.quote.place.order.form.1.section.2.label.save": "加入地址簿",
  "page.quote.place.order.form.1.section.2.label.sign": "收件人签字",

  "page.quote.place.order.form.1.back": "重新估价",
  "page.quote.place.order.form.1.next": "下一步",

  "page.quote.place.order.form.2": "货物信息",
  "page.quote.place.order.form.2.label.type": "货物类别",
  "page.quote.place.order.form.2.placeholder.type": "比如 “家具” “自行车”",
  "page.quote.place.order.form.2.empty.type": "请填写货物类别",
  "page.quote.place.order.form.2.label.method": "交货形式",
  "page.quote.place.order.form.2.label.quantity": "货板数量",
  "page.quote.place.order.form.2.label.length": "长",
  "page.quote.place.order.form.2.label.width": "宽",
  "page.quote.place.order.form.2.label.height": "高",
  "page.quote.place.order.form.2.label.weight": "货物总重量 lbs",
  "page.quote.place.order.form.2.label.note": "备注",
  "page.quote.place.order.form.2.placeholder.note": "请输入备注信息",
  "page.quote.place.order.form.2.label.safe": "不含危险品",
  "page.quote.place.order.form.2.empty.safe": "请确认是否不含危险品",

  "page.quote.place.order.form.2.back": "返回",
  "page.quote.place.order.form.2.next": "确认",

  "page.quote.place.order.form.3.label.price": "订单价格",
  "page.quote.place.order.form.3.label.date": "取货时间",
  "page.quote.place.order.form.3.label.info.pickup": "发货信息",
  "page.quote.place.order.form.3.label.type": "地址类别: ",
  "page.quote.place.order.form.3.label.ls": "需要升降尾板服务",
  "page.quote.place.order.form.3.label.info.delivery": "收货信息",
  "page.quote.place.order.form.3.label.type.cargo": "货物类别",
  "page.quote.place.order.form.3.label.info.cargo": "货物信息",
  "page.quote.place.order.form.3.label.weight": "重量",
  "page.quote.place.order.form.3.label.note": "备注",

  "page.quote.place.order.form.3.back": "返回",
  "page.quote.place.order.form.3.pay": "去支付",

  "page.quote.place.order.submit.address.error.content.pickup":
    "您输入的发货地址有误，是否继续提交",
  "page.quote.place.order.submit.address.error.content.delivery":
    "您输入的收货地址有误，是否继续提交",
  "page.quote.place.order.submit.address.error.confirm": "继续提交",
  "page.quote.place.order.submit.address.error.cancel": "取消",

  "page.quote.order.list.loading": "加载中...",
  "page.quote.order.list.empty.title": "暂无订单",
  "page.quote.order.list.empty.title.sub": "快去下单吧!",
  "page.quote.order.list.empty.goto.quote": "在线下单",
  "page.quote.order.list.item.label.order.id": "订单号",
  "page.quote.order.list.item.label.price": "订单价格",
  "page.quote.order.list.item.label.pickup": "发货点",
  "page.quote.order.list.item.label.delivery": "送货点",
  "page.quote.order.list.item.label.time.pickup": "取货时间",
  "page.quote.order.list.item.label.time.delivery": "送货时间",
  "page.quote.order.list.item.label.time.receive": "送达时间",
  "page.quote.order.list.item.label.type.cargo": "货物类别",
  "page.quote.order.list.item.label.size": "货物尺寸",
  "page.quote.order.list.item.label.size.length": "长",
  "page.quote.order.list.item.label.size.width": "宽",
  "page.quote.order.list.item.label.size.height": "高",
  "page.quote.order.list.item.label.pay": "支付",
  "page.quote.order.list.item.label.delete": "删除",

  "page.quote.order.detail.item.label.order.id": "订单号",
  "page.quote.order.detail.item.label.price": "订单价格",
  "page.quote.order.detail.item.label.info.pickup": "发货信息",
  "page.quote.order.detail.item.label.info.delivery": "收货信息",
  "page.quote.order.detail.item.label.type.address": "地址类别",
  "page.quote.order.detail.item.label.type.address.residential": "住宅区",
  "page.quote.order.detail.item.label.type.address.commercial": "商业区",
  "page.quote.order.detail.item.label.time.pickup": "取货时间",
  "page.quote.order.detail.item.label.time.delivery": "送货时间",
  "page.quote.order.detail.item.label.status.receive": "货物送达",
  "page.quote.order.detail.item.alert.message.receive": "提示: 司机已送达，请确认收货情况。如未收到，请在平台投诉。",
  "page.quote.order.detail.item.label.type.cargo": "货物类别",
  "page.quote.order.detail.item.label.size": "货物尺寸",
  "page.quote.order.detail.item.label.size.length": "长",
  "page.quote.order.detail.item.label.size.width": "宽",
  "page.quote.order.detail.item.label.size.height": "高",
  "page.quote.order.detail.item.label.size.weight": "重",
  "page.quote.order.detail.item.label.note": "备注",
  "page.quote.order.detail.item.content.bol": "点击此处浏览 BOL 表格",
  "page.quote.order.detail.item.label.info.payment": "付款信息",
  "page.quote.order.detail.item.label.pay": "支付",

  "page.quote.card.form.label.bill.address": "账单地址",
  "page.quote.card.form.required.bill.address": "请选择账单地址",
  "page.quote.card.form.label.bill.address.same": "和发货地址相同",
  "page.quote.card.form.label.bill.address.new": "输入一个新的账单地址",
  "page.quote.card.form.label.bill.address.new.name": "姓名",
  "page.quote.card.form.required.bill.address.new.name": "请输入姓名",
  "page.quote.card.form.placeholder.bill.address.new.name": "请输入姓名",
  "page.quote.card.form.label.bill.address.new.address.line.1": "地址行1",
  "page.quote.card.form.required.bill.address.new.address.line.1": "请输入地址信息",
  "page.quote.card.form.placeholder.bill.address.new.address.line.1": "请输入地址信息",
  "page.quote.card.form.label.bill.address.new.address.line.2": "地址行2",
  "page.quote.card.form.label.bill.address.new.zipcode": "Zip Code",
  "page.quote.card.form.required.bill.address.new.zipcode": "请输入Zip Code",
  "page.quote.card.form.placeholder.bill.address.new.zipcode": "请输入Zip Code",
  "page.quote.card.form.label.bill.address.new.city": "City",
  "page.quote.card.form.required.bill.address.new.city": "请输入City",
  "page.quote.card.form.placeholder.bill.address.new.city": "请输入City",
  "page.quote.card.form.label.bill.address.new.state": "State",
  "page.quote.card.form.required.bill.address.new.state": "请输入State",
  "page.quote.card.form.placeholder.bill.address.new.state": "请输入State",
  "page.quote.card.form.payment.message.top.alert": "在司机确认接单之前，平台不会进行扣款",
  "page.quote.card.form.payment.label.info": "付款信息",
  "page.quote.card.form.payment.label.card.number": "银行卡",
  "page.quote.card.form.payment.required.card": "请选择银行卡",
  "page.quote.card.form.payment.label.card.add": "添加新的银行卡号",
  "page.quote.card.form.payment.label.card.add.type": "银行卡类别",
  "page.quote.card.form.payment.required.card.add.type": "请选择银行卡类别",
  "page.quote.card.form.payment.placeholder.card.add.type": "请选择银行卡类别",
  "page.quote.card.form.payment.label.card.add.number": "银行卡号",
  "page.quote.card.form.payment.required.card.add.number": "请输入银行卡号",
  "page.quote.card.form.payment.error.card.add.number": "卡号格式错误",
  "page.quote.card.form.payment.label.card.add.month": "过期时间",
  "page.quote.card.form.payment.required.card.add.month": "请选择过期月份",
  "page.quote.card.form.payment.placeholder.card.add.month": "请选择过期月份",
  "page.quote.card.form.payment.required.card.add.year": "请选择过期年份",
  "page.quote.card.form.payment.placeholder.card.add.year": "请选择过期年份",
  "page.quote.card.form.payment.error.card.add.date": "过期时间错误",
  "page.quote.card.form.payment.label.card.add.cvv": "安全码",
  "page.quote.card.form.payment.required.card.add.cvv": "请输入安全码",
  "page.quote.card.form.payment.placeholder.card.add.cvv": "请输入安全码",
  "page.quote.card.form.payment.error.card.add.cvv": "安全码必须是3位数字",
  "page.quote.card.form.payment.label.card.add.save": "保存银行卡",
  "page.quote.card.form.payment.error.card.add": "请填写完整的银行卡信息",
  "page.quote.card.form.payment.label.back": "上一步",
  "page.quote.card.form.payment.label.confirm": "确认",
};
