import React, { createContext, useEffect, useState, useCallback } from "react";
import { IntlProvider } from "react-intl";
import jwt from "jsonwebtoken";
import intlMessagesZhCn from "@/i18n/locales/zh-CN";
import intlMessagesEN from "./i18n/locales/en";

import { RouterProvider } from "react-router-dom";
import router from "@/router";

const oneday = 86400000;

const detectDefaultLanguage = () => {
  const browserLanguage = navigator.language || "en";
  if (browserLanguage.startsWith("zh")) {
    return {
      locale: "zh-CN",
      messages: intlMessagesZhCn,
    };
  } else if (browserLanguage.startsWith("en")) {
    return {
      locale: "en",
      messages: intlMessagesEN,
    };
  } else {
    return {
      locale: "en",
      messages: intlMessagesEN,
    };
  }
};

const AppContext = createContext({
  phone: "",
  token: "",
  isLoggedIn: false,
  onLogin: () => {},
  onLogout: () => {},
  currentLanguage: detectDefaultLanguage,
  setCurrentLanguage: () => {},
});

const languageList = ["en", "zh-CN"];

const currentLanguageKey = "language";

const getLanguageIntlMessages = (val) => {
  localStorage.setItem(currentLanguageKey, val);
  switch (val) {
    case "en":
      return {
        locale: val,
        messages: intlMessagesEN,
      };
    case "zh-CN":
      return {
        locale: val,
        messages: intlMessagesZhCn,
      };
    default:
      return detectDefaultLanguage;
  }
};

let logoutTimer;

const calculateRemainDuration = (expTime) => {
  const currentTime = new Date().getTime(); // timestamp in ms
  const formatedExp = new Date(expTime).getTime();
  const remainDuration = formatedExp - currentTime;

  return remainDuration;
};

const getLocalToken = () => {
  const localPhone = localStorage.getItem("phone");
  const localToken = localStorage.getItem("token");
  const localShipperToken = localStorage.getItem("shipper_token");
  const localShipperPhone = localStorage.getItem("shipper_phone");
  const localExp = parseFloat(localStorage.getItem("expTime"));

  const remainDuration = calculateRemainDuration(localExp);
  if (remainDuration <= 0) {
    localStorage.removeItem("phone");
    localStorage.removeItem("token");
    localStorage.removeItem("expTime");
    localStorage.removeItem("uid");
    return null;
  }

  return {
    token: localToken,
    duration: remainDuration,
    phone: localPhone,
    shipperToken: localShipperToken,
    shipperPhone: localShipperPhone,
  };
};

const ContextProvider = () => {
  const tokenInfo = getLocalToken();
  let initialPhone;
  let initialToken;
  let initialShipperToken;
  let initialShipperPhone;
  if (tokenInfo) {
    initialToken = tokenInfo.token;
    initialPhone = tokenInfo.phone;
    initialShipperToken = tokenInfo.shipperToken;
    initialShipperPhone = tokenInfo.shipperPhone;
  }
  const [phone, setPhone] = useState(initialPhone);
  const [token, setToken] = useState(initialToken);
  const [shipperToken, setShipperToken] = useState(initialShipperToken);
  const [shipperPhone, setShipperPhone] = useState(initialShipperPhone);
  var isLoggedIn = !!token;
  var isShipperLoggedIn = !!shipperToken;

  const shipperLoginHandler = (shipperToken, shipperPhone) => {
    localStorage.setItem("shipper_phone", shipperPhone);
    localStorage.setItem("shipper_token", shipperToken);
    setShipperPhone(shipperPhone);
    setShipperToken(shipperToken);
  };

  const shipperLogoutHandler = useCallback(() => {
    setShipperToken(null);
    setShipperPhone(null);
    localStorage.removeItem("shipper_phone");
    localStorage.removeItem("shipper_token");
  }, []);

  const loginHandler = (token, phone) => {
    const payload = jwt.decode(token);

    localStorage.setItem("phone", phone);
    localStorage.setItem("token", token);
    localStorage.setItem("expTime", payload.expires * 1000);
    localStorage.setItem("uid", payload.token_id);
    setPhone(phone);
    setToken(token);
    const remainDuration = calculateRemainDuration(payload.expires * 1000);

    if (remainDuration <= oneday) {
      logoutTimer = setTimeout(logoutHandler, remainDuration); // 自动登出
    }

    // 跳转
    router.navigate("/orders");
  };

  const logoutHandler = useCallback((gotoLogin) => {
    setToken(null);
    setPhone(null);
    localStorage.removeItem("phone");
    localStorage.removeItem("token");
    localStorage.removeItem("expTime");
    localStorage.removeItem("uid");
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }

    if (gotoLogin) {
      router.navigate("/login");
    } else {
      router.navigate("/");
    }
  }, []);

  const [currentLanguage, setCurrentLanguage] = useState(detectDefaultLanguage);

  useEffect(() => {
    if (tokenInfo) {
      if (tokenInfo.duration < oneday) {
        logoutTimer = setTimeout(logoutHandler, tokenInfo.duration);
      }
    }

    const cacheLanguage = localStorage.getItem(currentLanguageKey);
    if (cacheLanguage !== null) {
      setCurrentLanguage(getLanguageIntlMessages(cacheLanguage));
    }
  }, []);

  const contextValue = {
    phone: phone,
    token: token,
    isLoggedIn: isLoggedIn,
    onLogin: loginHandler,
    onLogout: logoutHandler,

    shipperPhone: shipperPhone,
    shipperToken: shipperToken,
    isShipperLoggedIn: isShipperLoggedIn,
    onShipperLogin: shipperLoginHandler,
    onShipperLogout: shipperLogoutHandler,

    currentLanguage,
    setCurrentLanguage,
  };
  return (
    <AppContext.Provider value={contextValue}>
      <IntlProvider
        key={currentLanguage.locale}
        locale={currentLanguage.locale}
        messages={currentLanguage.messages}
      >
        <div className="App-Main">
          <RouterProvider router={router} />
        </div>
      </IntlProvider>
    </AppContext.Provider>
  );
};
export { ContextProvider, AppContext, getLanguageIntlMessages, languageList };
