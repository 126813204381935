import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import {
  Row,
  Col,
  Steps,
  Form,
  Input,
  Checkbox,
  InputNumber,
  message,
  Modal,
  Spin,
} from "antd";
import Image from "react-bootstrap/Image";

import topAlertIcon from "@/assets/top_alert_icon.png";
import phoneIcon from "@/assets/phone_icon@3x.png";
import locationIcon from "@/assets/location_icon@3x.png";
import palletIcon from "@/assets/pallet_icon@3x.png";
import boxIcon from "@/assets/box_icon@3x.png";

import { useIntl } from "react-intl";

import { BASE_API_URL } from "@/network.config";
import qs from "qs";

export default function (props) {
const intl = useIntl();

  const quoteData = props.quoteData;
  const isPallet = quoteData.is_pallet;
  console.log("quoteData", quoteData);

  const [finalData, setFinalData] = useState({});

  const [messageApi, messageContextHolder] = message.useMessage();
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [currentStep, setCurrentStep] = useState(0);

  const [enableStepOne, setEnableStepOne] = useState(false);
  const [enableStepTwo, setEnableStepTwo] = useState(false);

  const [stepOneForm] = Form.useForm();
  const [cargoForm] = Form.useForm();

  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);

  useEffect(() => {
    /*
    doc for finalData:
      weight: float = Field(gt=0)
      pickup_postal_code: str
      delivery_postal_code: str
      pickup_date: datetime.date
      delivery_date: datetime.date
      is_signature: Optional[bool] = False
      goods_num: Optional[int] = 1
      goods_model: Optional[str] = None
      goods_type: Optional[str] = None
      remark: Optional[str] = None
      pickup_address: AddressItem
      delivery_address: AddressItem

      is_pallet: bool = False
      quantity: Optional[int] = Field(ge=1)

      length: Optional[float] = Field(gt=0)
      width: Optional[float] = Field(gt=0)
      height: Optional[float] = Field(gt=0)

      pickup_type: Optional[PickupTypeEnum] = PickupTypeEnum.COMMERCIAL
      delivery_type: Optional[DeliveryTypeEnum] = DeliveryTypeEnum.COMMERCIAL
      pickup_ls: bool = False     # 取货是否包含 Liftgate Service
      delivery_ls: bool = False   # 取货是否包含 Liftgate Service
    */
    let data = {
      quote_price: quoteData.price,
      weight: quoteData.weight,
      pickup_postal_code: quoteData.pickup_zipcode,
      delivery_postal_code: quoteData.delivery_zipcode,
      pickup_date: quoteData.pickup_date,
      delivery_date: quoteData.delivery_date,
      is_signature: false,
      goods_num: 1,
      goods_type: null,
      remark: null,
      pickup_address: {
        name: null,
        phone: null,
        email: "",
        zipcode: quoteData.pickup_zipcode,
        state: quoteData.pickup_state,
        city: quoteData.pickup_city,
        county: null,
        address_line1: null,
        address_line2: null,
        is_save: false,
      },
      delivery_address: {
        name: null,
        phone: null,
        email: "",
        zipcode: quoteData.delivery_zipcode,
        state: quoteData.delivery_state,
        city: quoteData.delivery_city,
        county: null,
        address_line1: null,
        address_line2: null,
        is_save: false,
      },
      is_pallet: quoteData.is_pallet,
      quantity: quoteData.quantity,
      length: quoteData.length,
      width: quoteData.width,
      height: quoteData.height,
      pickup_type: quoteData.pickup_type,
      delivery_type: quoteData.delivery_type,
      pickup_ls: quoteData.pickup_ls,
      delivery_ls: quoteData.delivery_ls,
    };
    setFinalData(data);
  }, []);

  const [spinning, setSpinning] = useState(false);

  const addressCheck = async (is_pickup) => {
    setSpinning(true);
    try {
      let token = localStorage.getItem("shipper_token");
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const addressInfo = stepOneForm.getFieldsValue();
      const address = is_pickup
        ? addressInfo.pickup_address_line1
        : addressInfo.delivery_address_line1;

      const response = await fetch(
        `${BASE_API_URL}/api/v1/app/maps/place/autocomplete?${qs.stringify({
          input_val: address,
          types: "address",
          language: "en",
        })}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      setSpinning(false);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (
        result.status !== "OK" &&
        result.predictions &&
        Array.isArray(result.predictions) &&
        result.predictions.length > 0
      ) {
        console.error(`Address check failed: ${result.errmsg}`);
        messageApi.error(result.errmsg);
        return null;
      }

      let pass = false;
      for (const prediction of result.predictions) {
        if (
          prediction.description
            .toUpperCase()
            .includes(
              is_pickup
                ? `${addressInfo.pickup_city}, ${addressInfo.pickup_state}, USA`.toUpperCase()
                : `${addressInfo.delivery_city}, ${addressInfo.delivery_state}, USA`.toUpperCase()
            )
        ) {
          pass = true;
          break;
        }
      }

      return pass;
    } catch (error) {
      setSpinning(false);
      console.error("Address check error:", error);
      messageApi.error("Address check failed. Please try again.");
      return null;
    }
  };

  const confirmDialog = (content) => {
    return new Promise((resolve) => {
      modalApi.confirm({
        content,
        okText: intl.formatMessage({
          id: "page.quote.place.order.submit.address.error.confirm",
          description: "继续提交",
        }),
        cancelText: intl.formatMessage({
          id: "page.quote.place.order.submit.address.error.cancel",
          description: "取消",
        }),
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  };

  const onStepOneFormChange = async (showError = false) => {
    let data = stepOneForm.getFieldsValue();
    console.log("data", showError, data);
    if (!data.pickup_name) {
      if (showError) {
        messageApi.error(intl.formatMessage({
          id: "page.quote.place.order.form.1.section.1.empty.name",
          description: "发货人姓名不能为空",
        }));
      }
      return false;
    }
    if (!data.pickup_address_line1) {
      if (showError) {
        messageApi.error(intl.formatMessage({
          id: "page.quote.place.order.form.1.section.1.empty.line.1",
          description: "发货地址不能为空",
        }));
      }
      return false;
    }
    if (!data.pickup_phone) {
      if (showError) {
        messageApi.error(intl.formatMessage({
          id: "page.quote.place.order.form.1.section.1.empty.phone",
          description: "发货人电话不能为空",
        }));
      }
      return false;
    }
    if (!data.delivery_name) {
      if (showError) {
        messageApi.error(intl.formatMessage({
          id: "page.quote.place.order.form.1.section.2.empty.name",
          description: "收货人姓名不能为空",
        }));
      }
      return false;
    }
    if (!data.delivery_address_line1) {
      if (showError) {
        messageApi.error(intl.formatMessage({
          id: "page.quote.place.order.form.1.section.2.empty.line.1",
          description: "收货地址不能为空",
        }));
      }
      return false;
    }
    if (!data.delivery_phone) {
      if (showError) {
        messageApi.error(intl.formatMessage({
          id: "page.quote.place.order.form.1.section.2.empty.phone",
          description: "收货人电话不能为空",
        }));
      }
      return false;
    }
    // email valid
    if (data.pickup_email && !data.pickup_email.includes("@")) {
      if (showError) {
        messageApi.error(intl.formatMessage({
          id: "page.quote.place.order.form.1.error.email",
          description: "请输入正确的邮箱地址",
        }));
      }
      return false;
    }
    if (data.delivery_email && !data.delivery_email.includes("@")) {
      if (showError) {
        messageApi.error(intl.formatMessage({
          id: "page.quote.place.order.form.1.error.email",
          description: "请输入正确的邮箱地址",
        }));
      }
      return false;
    }

    setEnableStepOne(true);

    if (showError) {
      let is_pickup_pass = await addressCheck(true);
      console.log("is pickup address check pass", is_pickup_pass);

      if (!is_pickup_pass) {
        const pickupConfirm = await confirmDialog(
          intl.formatMessage({
            id: "page.quote.place.order.submit.address.error.content.pickup",
            description: "您输入的发货地址有误，是否继续提交",
          })
        );

        if (!pickupConfirm) {
          return false;
        } else {
          let is_delivery_pass = await addressCheck(false);
          console.log("is delivery address check pass", is_delivery_pass);

          if (!is_delivery_pass) {
            const deliveryConfirm = await confirmDialog(
              intl.formatMessage({
                id: "page.quote.place.order.submit.address.error.content.delivery",
                description: "您输入的收货地址有误，是否继续提交",
              })
            );

            if (!deliveryConfirm) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      } else {
        let is_delivery_pass = await addressCheck(false);
        console.log("is delivery address check pass", is_delivery_pass);

        if (!is_delivery_pass) {
          const deliveryConfirm = await confirmDialog(
            intl.formatMessage({
              id: "page.quote.place.order.submit.address.error.content.delivery",
              description: "您输入的收货地址有误，是否继续提交",
            })
          );

          if (!deliveryConfirm) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    }
    return true;
  };

  const onCargoFormChange = (showError = false) => {
    let data = cargoForm.getFieldsValue();
    console.log("data", data);
    if (!data.goods_type) {
      if (showError) {
        messageApi.error(intl.formatMessage({
          id: "page.quote.place.order.form.2.empty.type",
          description: "请填写货物类别",
        }));
      }
      return false;
    }
    if (!data.is_safe) {
      if (showError) {
        messageApi.error(intl.formatMessage({
          id: "page.quote.place.order.form.2.empty.safe",
          description: "请确认是否不含危险品",
        }));
      }
      return false;
    }
    setEnableStepTwo(true);
    return true;
  };

  const updateFinalData = async (step) => {
    console.log("updateFinalData", step);
    let data = finalData;

    if (step === 1) {
      if (!(await onStepOneFormChange(true))) {
        return;
      }
      let stepOneData = stepOneForm.getFieldsValue();
      data.pickup_address.name = stepOneData.pickup_name;
      data.pickup_address.phone = stepOneData.pickup_phone;
      data.pickup_address.email = stepOneData.pickup_email || "";
      data.pickup_address.is_save = stepOneData.pickup_is_save;
      data.pickup_address.address_line1 = stepOneData.pickup_address_line1;
      data.pickup_address.address_line2 = stepOneData.pickup_address_line2;
      data.delivery_address.name = stepOneData.delivery_name;
      data.delivery_address.phone = stepOneData.delivery_phone;
      data.delivery_address.email = stepOneData.delivery_email || "";
      data.delivery_address.is_save = stepOneData.delivery_is_save;
      data.delivery_address.address_line1 = stepOneData.delivery_address_line1;
      data.delivery_address.address_line2 = stepOneData.delivery_address_line2;
      data.is_signature = stepOneData.delivery_sign;
      setFinalData(data);
      setCurrentStep(1);
    }
    if (step === 2) {
      if (!onCargoFormChange(true)) {
        return;
      }
      let cargoData = cargoForm.getFieldsValue();
      data.goods_type = cargoData.goods_type;
      data.is_safe = cargoData.is_safe;
      data.remark = cargoData.note;
      setFinalData(data);
      setCurrentStep(2);
    }
    console.log("finalData", finalData);
  };

  return (
    <Container className="form-container">
      {modalContextHolder}
      {messageContextHolder}
      <Spin spinning={spinning} fullscreen />
      <Row style={{ marginBottom: 40 }}>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 16, offset: 4 }}
          xl={{ span: 16, offset: 4 }}
        >
          <Steps
            current={currentStep}
            items={[
              {
                title: useIntl().formatMessage({
                  id: "page.quote.place.order.step.address.info",
                  description: "地址信息",
                }),
              },
              {
                title: useIntl().formatMessage({
                  id: "page.quote.place.order.step.cargo.info",
                  description: "货物信息",
                }),
              },
              {
                title: useIntl().formatMessage({
                  id: "page.quote.place.order.step.order.info",
                  description: "订单详情",
                }),
              },
            ]}
          />
        </Col>
      </Row>
      {currentStep === 0 ? (
        <>
          <Form.Provider
            onFormChange={() => {
              onStepOneFormChange(false);
            }}
          >
            <div className="form-group">
              <div className="top-alert">
                <Image src={topAlertIcon} className="top-alert-icon" />
                <span>
                  {useIntl().formatMessage({
                    id: "page.quote.place.order.top.alert",
                    description: "请准确填写，因信息不准确造成损失需自行承担。",
                  })}
                </span>
              </div>
              <Form
                layout="vertical"
                variant="filled"
                name="address"
                form={stepOneForm}
                initialValues={quoteData}
              >
                <Row gutter={[97, 0]}>
                  <Col xs={24} md={12} xl={12}>
                    <p className="form-group-title">
                      {useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.1",
                        description: "发货人信息",
                      })}
                    </p>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.1.label.name",
                        description: "公司或发货人姓名",
                      })}
                      name="pickup_name"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.1.placeholder.name",
                          description: "请输入全名",
                        })}
                      />
                    </Form.Item>
                    <div className="form-group-subtitle">
                      <Image
                        src={locationIcon}
                        className="form-group-subtitle-icon"
                      />
                      <span className="form-group-subtitle-text">
                        {useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.1.label.address",
                          description: "详细发货地址",
                        })}
                      </span>
                    </div>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.1.label.line.1",
                        description: "地址行1",
                      })}
                      name="pickup_address_line1"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.1.placeholder.line.1",
                          description: "请输入地址信息",
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.1.label.line.2",
                        description: "地址行2",
                      })}
                      name="pickup_address_line2"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.1.placeholder.line.2",
                          description: "请输入地址信息",
                        })}
                      />
                    </Form.Item>
                    <Form.Item label="City" name="pickup_city">
                      <Input disabled />
                    </Form.Item>
                    <Row gutter={[24, 0]}>
                      <Col span={12}>
                        <Form.Item label="ZIP Code" name="pickup_zipcode">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="State" name="pickup_state">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="form-group-subtitle">
                      <Image
                        src={phoneIcon}
                        className="form-group-subtitle-icon"
                      />
                      <span className="form-group-subtitle-text">
                        {useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.1.label.contact",
                          description: "发货人联系方式",
                        })}
                      </span>
                    </div>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.1.label.phone",
                        description: "电话",
                      })}
                      name="pickup_phone"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.1.placeholder.phone",
                          description: "请输入电话号码",
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.1.label.email",
                        description: "邮箱",
                      })}
                      name="pickup_email"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.1.placeholder.email",
                          description: "请输入邮箱地址",
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      name="pickup_is_save"
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Checkbox>
                        {useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.1.label.save",
                          description: "加入地址簿",
                        })}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <div
                    style={{
                      width: 1,
                      position: "absolute",
                      left: "50%",
                      height: "100%",
                      background: "#CECAC4",
                    }}
                  ></div>
                  <Col xs={24} md={12} xl={12}>
                    <p className="form-group-title">
                      {useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.2",
                        description: "收货人信息",
                      })}
                    </p>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.2.label.name",
                        description: "公司或收货人姓名",
                      })}
                      name="delivery_name"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.2.placeholder.name",
                          description: "请输入全名",
                        })}
                      />
                    </Form.Item>
                    <div className="form-group-subtitle">
                      <Image
                        src={locationIcon}
                        className="form-group-subtitle-icon"
                      />
                      <span className="form-group-subtitle-text">
                        {useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.2.label.address",
                          description: "详细收货地址",
                        })}
                      </span>
                    </div>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.2.label.line.1",
                        description: "地址行1",
                      })}
                      name="delivery_address_line1"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.2.placeholder.line.1",
                          description: "请输入地址信息",
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.2.label.line.2",
                        description: "地址行2",
                      })}
                      name="delivery_address_line2"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.2.placeholder.line.2",
                          description: "请输入地址信息",
                        })}
                      />
                    </Form.Item>
                    <Form.Item label="City" name="delivery_city">
                      <Input disabled />
                    </Form.Item>
                    <Row gutter={[24, 0]}>
                      <Col span={12}>
                        <Form.Item label="ZIP Code" name="delivery_zipcode">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="State" name="delivery_state">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="form-group-subtitle">
                      <Image
                        src={phoneIcon}
                        className="form-group-subtitle-icon"
                      />
                      <span className="form-group-subtitle-text">
                        {useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.2.label.contact",
                          description: "收货人联系方式",
                        })}
                      </span>
                    </div>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.2.label.phone",
                        description: "电话",
                      })}
                      name="delivery_phone"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.2.placeholder.phone",
                          description: "请输入电话号码",
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.1.section.2.label.email",
                        description: "邮箱",
                      })}
                      name="delivery_email"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.2.placeholder.email",
                          description: "请输入邮箱地址",
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      name="delivery_is_save"
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Checkbox>
                        {useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.2.label.save",
                          description: "加入地址簿",
                        })}
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="delivery_sign"
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Checkbox>
                        {useIntl().formatMessage({
                          id: "page.quote.place.order.form.1.section.2.label.sign",
                          description: "收件人签字",
                        })}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Form.Provider>
          <div className="bottom-buttons">
            <div className="bottom-button pre-step" onClick={props.goToQuote}>
              <span>
                {useIntl().formatMessage({
                  id: "page.quote.place.order.form.1.back",
                  description: "重新估价",
                })}
              </span>
            </div>
            <div
              className={`bottom-button ${
                enableStepOne ? "next-step-enable" : "next-step-disable"
              }`}
              onClick={async () => {
                updateFinalData(1);
              }}
            >
              <span>
                {useIntl().formatMessage({
                  id: "page.quote.place.order.form.1.next",
                  description: "下一步",
                })}
              </span>
            </div>
          </div>
        </>
      ) : null}
      {currentStep === 1 ? (
        <>
          <Form.Provider
            onFormChange={() => {
              onCargoFormChange(false);
            }}
          >
            <div className="form-group">
              <Form
                layout="vertical"
                variant="filled"
                name="address"
                form={cargoForm}
                initialValues={quoteData}
              >
                <p className="form-group-title">
                  {useIntl().formatMessage({
                    id: "page.quote.place.order.form.2",
                    description: "货物信息",
                  })}
                </p>
                <Row gutter={[80, 0]}>
                  <Col xs={24} md={12} xl={12}>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.2.label.type",
                        description: "货物类别",
                      })}
                      name="goods_type"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.2.placeholder.type",
                          description: "比如 “家具” “自行车”",
                        })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label={useIntl().formatMessage({
                    id: "page.quote.place.order.form.2.label.method",
                    description: "货物形式",
                  })}
                >
                  <Row gutter={[80, 0]}>
                    <Col span={12}>
                      <div
                        className={`delivery-method-item ${
                          isPallet
                            ? "delivery-method-item-active"
                            : "delivery-method-item-normal"
                        }`}
                        onClick={() => {}}
                      >
                        <Image
                          src={palletIcon}
                          className={"delivery-method-item-icon"}
                        />
                        <p>Pallet(s)</p>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div
                        className={`delivery-method-item ${
                          isPallet
                            ? "delivery-method-item-normal"
                            : "delivery-method-item-active"
                        }`}
                        onClick={() => {}}
                      >
                        <Image
                          src={boxIcon}
                          className={"delivery-method-item-icon"}
                        />
                        <p>Box(es)</p>
                      </div>
                    </Col>
                  </Row>
                </Form.Item>
                <Row gutter={[80, 0]}>
                  {isPallet ? (
                    <Col xs={24} md={12} xl={12}>
                      <Form.Item
                        label={useIntl().formatMessage({
                          id: "page.quote.place.order.form.2.label.quantity",
                          description: "货板数量",
                        })}
                        name="quantity"
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={1}
                          controls={false}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col xs={24} md={12} xl={12}>
                      <Row gutter={[24, 0]}>
                        <Col span={8}>
                          <Form.Item
                            label={useIntl().formatMessage({
                              id: "page.quote.place.order.form.2.label.length",
                              description: "长",
                            })}
                            name="length"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0.01}
                              controls={false}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label={useIntl().formatMessage({
                              id: "page.quote.place.order.form.2.label.width",
                              description: "宽",
                            })}
                            name="width"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0.01}
                              controls={false}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label={useIntl().formatMessage({
                              id: "page.quote.place.order.form.2.label.height",
                              description: "高",
                            })}
                            name="height"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0.01}
                              controls={false}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col xs={24} md={12} xl={12}>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.2.label.weight",
                        description: "货物重量 lbs",
                      })}
                      name="weight"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0.01}
                        controls={false}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[80, 0]}>
                  <Col xs={24} md={12} xl={12}>
                    <Form.Item
                      label={useIntl().formatMessage({
                        id: "page.quote.place.order.form.2.label.note",
                        description: "备注",
                      })}
                      name="note"
                    >
                      <Input
                        placeholder={useIntl().formatMessage({
                          id: "page.quote.place.order.form.2.placeholder.note",
                          description: "请输入备注信息",
                        })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="is_safe"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox>
                    {useIntl().formatMessage({
                      id: "page.quote.place.order.form.2.label.safe",
                      description: "不含危险品",
                    })}
                  </Checkbox>
                </Form.Item>
              </Form>
            </div>
          </Form.Provider>
          <div className="bottom-buttons">
            <div
              className="bottom-button pre-step"
              onClick={() => {
                setCurrentStep(0);
              }}
            >
              <span>
                {useIntl().formatMessage({
                  id: "page.quote.place.order.form.2.back",
                  description: "返回",
                })}
              </span>
            </div>
            <div
              className={`bottom-button ${
                enableStepTwo ? "next-step-enable" : "next-step-disable"
              }`}
              onClick={() => {
                updateFinalData(2);
              }}
            >
              <span>
                {useIntl().formatMessage({
                  id: "page.quote.place.order.form.2.next",
                  description: "确认",
                })}
              </span>
            </div>
          </div>
        </>
      ) : null}
      {currentStep === 2 ? (
        <>
          <Row gutter={[16, 0]}>
            <Col xs={24} md={12} xl={12}>
              <div className="preview-group">
                <p className="group-title">
                  {useIntl().formatMessage({
                    id: "page.quote.place.order.form.3.label.price",
                    description: "订单价格",
                  })}
                </p>
                <p className="price">$ {finalData.quote_price}</p>
              </div>
            </Col>
            <Col xs={24} md={12} xl={12}>
              <div className="preview-group">
                <p className="group-title">
                  {useIntl().formatMessage({
                    id: "page.quote.place.order.form.3.label.date",
                    description: "取货时间",
                  })}
                </p>
                <p className="content pb-185">
                  {quoteData.pickup_date} - {quoteData.delivery_date}
                </p>
              </div>
            </Col>
          </Row>
          <div className="preview-group">
            <Row gutter={[81, 0]} style={{ position: "relative" }}>
              <Col xs={24} md={12} xl={12}>
                <p className="group-title">
                  {useIntl().formatMessage({
                    id: "page.quote.place.order.form.3.label.info.pickup",
                    description: "发货信息",
                  })}
                </p>
                <p className="cotent">
                  {finalData.pickup_address.name}{" "}
                  {finalData.pickup_address.phone}
                </p>
                <p className="cotent">
                  {finalData.pickup_address.address_line1}{" "}
                  {finalData.pickup_address.address_line2}{" "}
                  {finalData.pickup_address.city},{" "}
                  {finalData.pickup_address.state}{" "}
                  {finalData.pickup_address.zipcode}
                </p>
                <p className="cotent">
                  {finalData.pickup_ls ? useIntl().formatMessage({
                    id: "page.quote.place.order.form.3.label.ls",
                    description: "Liftgate service required",
                  }) : ""}
                </p>
                <p className="cotent">
                  {useIntl().formatMessage({
                    id: "page.quote.place.order.form.3.label.type",
                    description: "地址类别: ",
                  })}
                  {finalData.pickup_address.residential_pickup
                    ? useIntl().formatMessage({
                        id: "page.quote.form.session.address.type.residential",
                        description: "住宅区",
                      })
                    : useIntl().formatMessage({
                        id: "page.quote.form.session.address.type.commercial",
                        description: "商业区",
                      })}
                </p>
              </Col>
              <div
                style={{
                  width: 1,
                  position: "absolute",
                  left: "50%",
                  height: "100%",
                  background: "#CECAC4",
                }}
              ></div>
              <Col xs={24} md={12} xl={12}>
                <p className="group-title">
                  {useIntl().formatMessage({
                    id: "page.quote.place.order.form.3.label.info.delivery",
                    description: "收货信息",
                  })}
                </p>
                <p className="cotent">
                  {finalData.delivery_address.name}{" "}
                  {finalData.delivery_address.phone}
                </p>
                <p className="cotent">
                  {finalData.delivery_address.address_line1}{" "}
                  {finalData.delivery_address.address_line2}{" "}
                  {finalData.delivery_address.city},{" "}
                  {finalData.delivery_address.state}{" "}
                  {finalData.delivery_address.zipcode}
                </p>
                <p className="cotent">
                  {finalData.delivery_ls ? useIntl().formatMessage({
                    id: "page.quote.place.order.form.3.label.ls",
                    description: "Liftgate service required",
                  }) : ""}
                </p>
                <p className="cotent">
                  {useIntl().formatMessage({
                    id: "page.quote.place.order.form.3.label.type",
                    description: "地址类别: ",
                  })}
                  {finalData.delivery_address.residential_pickup
                    ? useIntl().formatMessage({
                        id: "page.quote.form.session.address.type.residential",
                        description: "住宅区",
                      })
                    : useIntl().formatMessage({
                        id: "page.quote.form.session.address.type.commercial",
                        description: "商业区",
                      })}
                </p>
              </Col>
            </Row>
          </div>
          <div className="preview-group preview-group-flex">
            <div className="item">
              <p className="group-title">
                {useIntl().formatMessage({
                  id: "page.quote.place.order.form.3.label.type.cargo",
                  description: "货物类别",
                })}
              </p>
              <p className="cotent">{finalData.goods_type}</p>
            </div>
            <div className="divider"></div>
            <div className="item">
              <p className="group-title">
                {useIntl().formatMessage({
                  id: "page.quote.place.order.form.3.label.info.cargo",
                  description: "货物信息",
                })}
              </p>
              <p className="cotent">
                {finalData.quantity} {finalData.is_pallet ? "pallet" : "box"} -
                {useIntl().formatMessage({
                  id: "page.quote.place.order.form.3.label.weight",
                  description: "重量",
                })}{" "}
                {finalData.weight}lbs
              </p>
            </div>
            <div className="divider"></div>
            <div className="item">
              <p className="group-title">
                {useIntl().formatMessage({
                  id: "page.quote.place.order.form.3.label.note",
                  description: "备注",
                })}
              </p>
              <p className="cotent">{finalData.remark}</p>
            </div>
          </div>
          <div className="bottom-buttons">
            <div
              className="bottom-button pre-step"
              onClick={() => {
                setCurrentStep(1);
              }}
            >
              <span>
                {useIntl().formatMessage({
                  id: "page.quote.place.order.form.3.back",
                  description: "返回",
                })}
              </span>
            </div>
            <div
              className="bottom-button next-step-enable"
              onClick={async () => {
                if (placeOrderLoading) {
                  return;
                }
                setPlaceOrderLoading(true);
                await props.placeOrder(finalData);
                setPlaceOrderLoading(false);
              }}
            >
              {placeOrderLoading ? (
                <div>
                  <Spin
                    style={{
                      color: "#fff",
                      fontSize: "17px",
                      fontWeight: "600",
                      lineHeight: "60px",
                    }}
                  />
                </div>
              ) : (
                <span>
                  {useIntl().formatMessage({
                    id: "page.quote.place.order.form.3.pay",
                    description: "去支付",
                  })}
                  $ {finalData.quote_price}
                </span>
              )}
            </div>
          </div>
        </>
      ) : null}
    </Container>
  );
}
